import request from "@/utils/request"

// 线索一览查询
export function serverApiClueList(query) {
  return request({
    url: "/clueSmart/listPage",
    method: "get",
    params: query
  })
}

//新增线索
export function serverApiAddClue(data) {
  return request({
    url: "/clueSmart/save",
    method: "post",
    data
  })
}

//线索详情
export function listById(data) {
  return request({
    url: "/clueSmart/listById",
    method: "get",
    params: data
  })
}

//编辑线索
export function update(data) {
  return request({
    url: "/clueSmart/update",
    method: "post",
    data
  })
}
//是否冻结
export function notFreeze(data) {
  return request({
    url: "/clueSmart/notFreeze",
    method: "get",
    params: data
  })
}
//线索新增企业客户合作伙伴匹配
export function listPartnerName(data) {
  return request({
    url: "/clueSmart/listPartnerName",
    method: "get",
    params: data
  })
}


//线索新增企业客户企业匹配
export function listCompanyName(data) {
  return request({
    url: "/clueSmart/listCompanyName",
    method: "get",
    params: data
  })
}

//线索阶段修改
export function updateStage(data) {
  return request({
    url: "/clueSmart/updateStage",
    method: "post",
    data
  })
}
//线索标签新增
export function savelabel(data) {
  return request({
    url: "/clueSmart/saveLabel",
    method: "post",
    data
  })
}
//线索标签修改
export function updateInformationLabel(data) {
  return request({
    url: "/clueSmart/updateLabel",
    method: "post",
    data
  })
}
//线索标签详情
export function getBylabelId(query) {
  return request({
    url: "/clueSmart/listClueLabel",
    method: "get",
    params: query
  })
}

//线索自动匹配
export function listCompanyId(query) {
  return request({
    url: "/clueSmartAutomation/listCompanyId",
    method: "get",
    params: query
  })
}
//线索自动匹配数据一览
export function listPage(query) {
  return request({
    url: "/clueSmartAutomation/listPage",
    method: "get",
    params: query
  })
}



//配置企业 将勾选的企业加入已选
export function addJoin(data) {
  return request({
    url: "/clueSmartAutomation/addJoin",
    method: "post",
    data
  })
}
//设置关联度
export function correlation(data) {
  return request({
    url: "/clueSmartAutomation/correlation",
    method: "post",
    data
  })
}

//配置企业 查询加入已选
export function serverApiSelectedCompanyPage(data) {
  return request({
    url: "/clueSmartMatched/listPage",
    method: "get",
    params: data
  })
}

//编写理由
export function serverApiUpdateCompanyReason(data) {
  return request({
    url: "/ServerClueController/updateCompanyReason",
    method: "post",
    data
  })
}

//移除已选中的企业
export function deleteById(data) {
  return request({
    url: "/clueSmartMatched/deleteById",
    method: "post",
    data
  })
}
//根据线索查询押金、线索信息
export function ListPrice(data) {
  return request({
    url: "/clueSmartMatched/ListPrice",
    method: "get",
    params: data
  })
}
//修改金额
export function editPrice(data) {
  return request({
    url: "/clueSmartMatched/editPrice",
    method: "post",
    data
  })
}



//发布已选
export function notRelease(data) {
  return request({
    url: "/clueSmartMatched/notRelease",
    method: "post",
    data
  })
}
//线索头部详情
export function listTopById(query) {
  return request({
    url: "/clueSmart/listTopById",
    method: "get",
    params: query
  })
}
//线索查看
export function listUserCheckPage(query) {
  return request({
    url: "/clueSmartBrowsing/listPage",
    method: "get",
    params: query
  })
}
//线索查看人数
export function userCheck(query) {
  return request({
    url: "/clueSmartBrowsing/userCheck",
    method: "get",
    params: query
  })
}
//线索分享一览
export function listUserSharePage(query) {
  return request({
    url: "/clueSmartShare/listPage",
    method: "get",
    params: query
  })
}
//线索分享数
export function userShare(query) {
  return request({
    url: "/clueSmartShare/userShare",
    method: "get",
    params: query
  })
}
//推送企业数据一览
export function listRecommendPage(query) {
  return request({
    url: "/clueSmartMatched/listRecommendPage",
    method: "get",
    params: query
  })
}
//推送企业下架
export function recommendById(data) {
  return request({
    url: "/clueSmartMatched/recommendById",
    method: "post",
    data
  })
}
//参与数据一览
export function listParticipationPage(data) {
  return request({
    url: "/clueSmartMatched/listParticipationPage",
    method: "get",
    params: data
  })
}
//统计参加的企业数
export function participationCount(data) {
  return request({
    url: "/clueSmartMatched/participationCount",
    method: "get",
    params: data
  })
}
//根据企业全称模糊匹配
export function listCompanyFullName(data) {
  return request({
    url: "/wxApiUserSmart/listCompanyFullName",
    method: "get",
    params: data
  })
}
//保存已选标签--线索
export function insertMatchCompanyLabel(data) {
  return request({
    url: "/clueSmart/insertMatchCompanyLabel",
    method: "post",
    data
  })
}
//修改历史匹配标签数据--线索
export function updateMatchedInformationLabel(data) {
  return request({
    url: "/clueSmart/updateMatchedInformationLabel",
    method: "post",
    data
  })
}

/**
 * 线索评估-详情
 */
export function getClueScore(data) {
  return request({
    url: "/clueSmart/getClueScore",
    method: "get",
    params: data
  })
}

/**
 * 转换商机
 */
export function transformBusinessOpportunity(data) {
  return request({
    url: "/clueSmart/transformBusinessOpportunity",
    method: "get",
    params: data
  })
}

//线索-服务商列表
export function selectClueCompanyPage(data) {
  return request({
    url: "/clueCompany/selectClueCompanyPage",
    method: "post",
    data
  })
}

// 模糊查询智选企业-分页
export function selectByCompanyName(data) {
  return request({
    url: "/clueCompany/selectByCompanyName",
    method: "post",
    data
  })
}

// 线索-新增服务商
export function insertClueCompany(data) {
  return request({
    url: "/clueCompany/insertClueCompany",
    method: "post",
    data
  })
}

// 线索-编辑服务商
export function updateClueCompany(data) {
  return request({
    url: "/clueCompany/updateClueCompany",
    method: "post",
    data
  })
}

// 线索-服务商 冻结/解冻
export function updateClueCompanyFreeze(data) {
  return request({
    url: "/clueCompany/updateClueCompanyFreeze",
    method: "post",
    data
  })
}


/**
 * 编辑服务商 ---查询单个
 */
export function getClueCompanyDetail(data) {
  return request({
    url: "/clueCompany/getClueCompanyDetail",
    method: "get",
    params: data
  })
}

// 绑定智选企业的小程序用户信息-分页
export function listPagePeople(data) {
  return request({
    url: "/clueCompany/listPage",
    method: "post",
    data
  })
}

// 线索-服务商 移除
export function removeClueCompany(data) {
  return request({
    url: "/clueCompany/removeClueCompany",
    method: "post",
    data
  })
}

// 线索-服务商新增企业联系人
export function insertClueContacts(data) {
  return request({
    url: "/clueCompany/insertClueContacts",
    method: "post",
    data
  })
}

// 线索-专家列表
export function getClueSpecialistPage(data) {
  return request({
    url: "/clueSpecialist/getClueSpecialistPage",
    method: "post",
    data
  })
}


// 线索-专家 冻结/解冻
export function updateClueSpecialistFreeze(data) {
  return request({
    url: "/clueSpecialist/updateClueSpecialistFreeze",
    method: "post",
    data
  })
}

/**
 * 线索-专家详情 ---查询单个
 */
export function getClueSpecialistDetail(data) {
  return request({
    url: "/clueSpecialist/getClueSpecialistDetail",
    method: "get",
    params: data
  })
}

// 线索-编辑专家
export function updateClueSpecialist(data) {
  return request({
    url: "/clueSpecialist/updateClueSpecialist",
    method: "post",
    data
  })
}

// 模糊查询专家数据-分页
export function getClueSpecialistResult(data) {
  return request({
    url: "/clueSpecialist/getClueSpecialistResult",
    method: "post",
    data
  })
}

// 线索-新增专家
export function insertClueSpecialist(data) {
  return request({
    url: "/clueSpecialist/insertClueSpecialist",
    method: "post",
    data
  })
}

// 线索-专家 移除
export function removeClueSpecialist(data) {
  return request({
    url: "/clueSpecialist/removeClueSpecialist",
    method: "post",
    data
  })
}

// 线索日志列表
export function getClueSpecialistResultClueLog(data) {
  return request({
    url: "/clueLog/getClueSpecialistResult",
    method: "post",
    data
  })
}

// 新增线索日志
export function insertClueLog(data) {
  return request({
    url: "/clueLog/insertClueLog",
    method: "post",
    data
  })
}

/**
 * 线索-日志 冻结
 */
export function updateClueLogFreeze(data) {
  return request({
    url: "/clueLog/updateClueLogFreeze",
    method: "post",
    data
  })
}

// 线索评估-新增
export function insertClueScore(data) {
  return request({
    url: "/clueSmart/insertClueScore",
    method: "post",
    data
  })
}
// 线索评估-新增
export function updateClueScore(data) {
  return request({
    url: "/clueSmart/updateClueScore",
    method: "post",
    data
  })
}

// 获取保存唯一编号
export function saveNumber(data) {
  return request({
    url: "/clueSmart/saveNumber",
    method: "get",
    params: data
  })
}

// 导出线索需求文档 /clueSmart/downloadPdf
export function downloadPdf(data) {
  return request({
    url: "/clueSmart/downloadPdf",
    method: "get",
    responseType: "blob",
    params: data
  })
}

// 导出线索甄选文档 /clueSmart/downloadPdf
export function downloadAnalysisPdf(data) {
  return request({
    url: "/clueSmart/downloadAnalysisPdf",
    method: "get",
    responseType: "blob",
    params: data
  })
}

//保存并匹配已选标签--线索和商机
export function insertMatchClueLabel(data) {
  return request({
    url: "/clueSmart/insertMatchClueLabel",
    method: "post",
    data
  })
}